<template>
    <div class="screen_filter"></div>
    <!-- 상품 팝업 시작 -->
    <div v-if="this.popup.whichPopup == '상품'" class="popup popup_ai">
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="check" />
        </button>
        <div class="inner">
            <div class="product_title">
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ this.popup.data.provider_name }}</p>
                </div>
                <div class="btn_wrap">
                    <div class="share click_on">
                        <img src="@/assets/images/common/icon_share_border.svg" alt="클립 아이콘" />
                        <img src="@/assets/images/common/icon_share_b.svg" alt="클립 아이콘" />
                    </div>
                    <button
                        class="common_button"
                        @click="this.$router.push(`/product/detail/${this.popup.data.product_pk}`)"
                    >
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/search_w.svg" alt="search" />
                        </div>
                        <p>상세보기</p>
                    </button>
                </div>

                <h1>
                    {{ this.popup.data.product_name }}
                </h1>
            </div>

            <div class="product">
                <div class="slide_wrap">
                    <div v-if="this.popup.data.product_image.length == 0" class="product_image default">
                        <img src="@/assets/images/common/no_image.svg" />
                    </div>
                    <div v-else-if="this.popup.data.product_image.length == 1" class="product_image">
                        <img
                            :src="this.popup.data.product_image[0].path + this.popup.data.product_image[0].save_name"
                            :alt="this.popup.data.product_image[0].original_name"
                        />
                    </div>
                    <swiper v-else v-bind="swiperOption">
                        <swiper-slide v-for="(item, i) in this.popup.data.product_image" :key="i">
                            <div class="product_image">
                                <img :src="item.path + item.save_name" :alt="item.original_name" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <div class="scale_wrap">
                    <div class="scale">
                        <p>최소구매수량</p>
                        <h1>
                            {{ this.popup.data.product_moq }}
                        </h1>
                    </div>
                    <div class="scale">
                        <p>예상마진</p>
                        <h1>{{ this.popup.data.product_margin }}</h1>
                    </div>
                    <div class="scale">
                        <p>공급가</p>
                        <h1 v-if="this.popup.data.supply_price_exposure_yn != '비공개'">
                            {{ this.numberFormat(this.popup.data.supply_price) }}<span>원</span>
                        </h1>
                        <h1 v-else>{{ this.popup.data.supply_price_exposure_yn }}</h1>
                    </div>
                    <div class="scale">
                        <p>소비자가</p>
                        <h1 v-if="this.popup.data.consumer_price_exposure_yn != '비공개'">
                            {{ this.numberFormat(this.popup.data.consumer_price) }}<span>원</span>
                        </h1>
                        <h1 v-else>{{ this.popup.data.consumer_price_exposure_yn }}</h1>
                    </div>
                </div>

                <span class="line"></span>
                <div class="graph_container">
                    <div class="total_percent">
                        <h3>종합 매칭 수준</h3>
                        <p><span id="graph_percent">0</span>%</p>
                    </div>
                    <canvas class="graph" id="graph" width="700" height="700"></canvas>
                </div>
            </div>
        </div>
        <!--팝업 처음 화면 끝-->
    </div>
    <!-- 상품 팝업 끝 -->

    <!-- 공급자, 판매자 팝업 시작 -->
    <div
        v-if="this.popup.whichPopup == '공급자' || this.popup.whichPopup == '판매자'"
        class="popup popup_ai"
    >
        <button type="button" @click="closePopup">
            <img src="@/assets/images/common/icon_close_w.svg" alt="close" />
        </button>

        <div class="inner">
            <div class="product_title">
                <div class="user user_seller" v-if="this.popup.whichPopup == '판매자'">
                    <span>판매자</span>
                    <p>{{ this.popup.data.name }}</p>
                </div>
                <div class="user user_provider" v-else>
                    <span>공급자</span>
                    <p>{{ this.popup.data.name }}</p>
                </div>
                <div class="btn_wrap">
                    <div class="share click_on">
                        <img src="@/assets/images/common/icon_share_border.svg" alt="클립 아이콘" />
                        <img src="@/assets/images/common/icon_share_b.svg" alt="클립 아이콘" />
                    </div>
                    <button
                        class="common_button"
                        @click="
                            this.$router.push(
                                `/${this.popup.whichPopup == '판매자' ? 'seller' : 'provider'}/detail/${
                                    this.popup.data.id
                                }`,
                            )
                        "
                    >
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/search_w.svg" alt="search" />
                        </div>
                        <p>상세보기</p>
                    </button>
                </div>
            </div>

            <div class="product">
                <div class="product_image">
                    <img
                        v-if="this.popup.data.original_name"
                        :src="this.popup.data.path + this.popup.data.save_name"
                        :alt="this.popup.data.original_name"
                    />
                    <img
                        v-else-if="this.popup.whichPopup == '공급자'"
                        src="@/assets/images/common/charactor_02.png"
                        alt="공급자 아이콘"
                    />
                    <img v-else src="@/assets/images/common/charactor_01.png" alt="판매자 아이콘" />
                </div>

                <div class="certificate_wrap">
                    <div class="certificate">
                        <p>본인인증</p>
                        <div
                            class="btn_check"
                            :class="this.popup.data.user_certification_yn == 'false' ? 'disabled' : ''"
                        >
                            <img src="@/assets/images/common/icon_check_w.svg" />
                        </div>
                    </div>
                    <div class="certificate">
                        <p>사업자인증</p>
                        <div
                            class="btn_check"
                            :class="this.popup.data.businessman_certification_yn == 'false' ? 'disabled' : ''"
                        >
                            <img src="@/assets/images/common/icon_check_w.svg" />
                        </div>
                    </div>
                    <div v-if="this.popup.whichPopup != '공급자'" class="certificate">
                        <p>채널인증</p>
                        <div
                            class="btn_check"
                            :class="this.popup.data.channel_certification_yn == 'false' ? 'disabled' : ''"
                        >
                            <img src="@/assets/images/common/icon_check_w.svg" />
                        </div>
                    </div>
                    <div class="certificate">
                        <p>거래역량인증</p>
                        <div
                            class="btn_check"
                            :class="this.popup.data.sales_certification_yn == 'false' ? 'disabled' : ''"
                        >
                            <img src="@/assets/images/common/icon_check_w.svg" />
                        </div>
                    </div>
                </div>

                <!-- <span class="line"></span> -->
                <div class="graph_container">
                    <div class="total_percent">
                        <h3>종합 매칭 수준</h3>
                        <p><span id="graph_percent">0</span>%</p>
                    </div>
                    <canvas class="graph" id="graph" width="700" height="700"></canvas>
                </div>
            </div>
        </div>
    </div>
    <!-- 공급자, 판매자 팝업 끝 -->
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination]);

import { mapState } from "vuex";

import { numberFormat } from "@/utils/numberFormat";
import { counting } from "@/utils/counting";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [ numberFormat, counting],
    computed: {
        ...mapState("ai", ["popup"]),
        ...mapState("user", ["user"]),
    },
    data() {
        return {
            rateAvg: 0,
            graphData: [],
            meta_token: 0,

            swiperOption: {
                loop: true,
                navigation: true,
                pagination: true,
            },
        };
    },
    created() {
        if (this.popup.whichPopup == "상품") {
            // 상품추천 - (판매자계정)
            this.graphData = [
                this.popup.data.rate.category_rate * 1, //카테고리
                this.popup.data.rate.way_rate * 1, //공급방법
                this.popup.data.rate.condi_rate * 1, //제품특징
                this.popup.data.rate.start_rate * 1, //제품공급시기
                this.popup.data.rate.margin_rate * 1, //예상마진
                this.popup.data.rate.price_rate * 1, //단가
            ];
        } else {
            // 공급자추천-(판매자계정) & 판매자추천-(공급자계정)
            this.graphData = [
                this.popup.data.rate.category_rate * 1, //카테고리
                this.popup.data.rate.career_rate * 1, //공급경력 & 판매경력
                this.popup.data.rate.budget_rate * 1, //공급가 & 준비예산
                this.popup.data.rate.start_rate * 1, //예상제조기간 & 판매가능시점
                this.popup.data.rate.margin_rate * 1, //예상마진
                this.popup.data.rate.price_rate * 1, //단가 & 희망단가 (둘다 평균임)
            ];
        }
        const total = this.graphData.reduce((prev, cur) => prev + cur);
        this.rateAvg = Math.round((total / 6) * 10) / 10;
    },
    mounted() {
        // 그래프 그릴 데이터 배열로 변환
        this.drawAIGraph();
        this.graphAnimate(this.graphData);
        this.counting(document.getElementById("graph_percent"), this.rateAvg);
    },
    updated() {
        this.drawAIGraph(this.graphData);
        document.getElementById("graph_percent").innerText = this.rateAvg;
    },
    methods: {
        // 팝업 닫기
        closePopup() {
            this.$store.commit("ai/closePopup");
        },
        // AI 그래프 그리기
        drawAIGraph(start = [0, 0, 0, 0, 0, 0]) {
            const canvas = document.getElementById("graph");
            const ctx = canvas.getContext("2d");

            this.drawGraph(ctx, start);
        },
        // AI 그래프 애니메이션
        graphAnimate(data) {
            const canvas = document.getElementById("graph");
            const ctx = canvas.getContext("2d");

            let current = [0, 0, 0, 0, 0, 0];

            const graphInterval = setInterval(() => {
                ctx.clearRect(0, 0, 700, 700);
                this.drawGraph(ctx, current);
                if (data.every((el, i) => el == current[i])) {
                    clearInterval(graphInterval);
                } else {
                    current = current.map((el, i) => (el == data[i] ? el : el + 1));
                }
            }, 7);
        },
        // 그래프 그리는 함수
        drawGraph(ctx, data) {
            const circleSize = 20;
            const r = 300;
            const fontSize = 25;
            drawBackground(ctx, r);
            drawTags(ctx, r + circleSize + fontSize, fontSize, this);
            drawData(ctx, r, data);
            drawCircles(ctx, r, circleSize, fontSize);

            // 입력 데이터 그리는 함수
            function drawData(ctx, r, data) {
                ctx.beginPath();
                for (let i = 0; i < 6; i++) {
                    const dataPercent = data[i] / 100;
                    ctx.lineTo(
                        350 +
                            r * Math.cos((Math.PI / 3) * i - Math.PI / 2) * (4 / 7) * dataPercent +
                            r * Math.cos((Math.PI / 3) * i - Math.PI / 2) * (3 / 7),
                        350 +
                            r * Math.sin((Math.PI / 3) * i - Math.PI / 2) * (4 / 7) * dataPercent +
                            r * Math.sin((Math.PI / 3) * i - Math.PI / 2) * (3 / 7),
                    );
                }
                ctx.closePath();
                ctx.strokeStyle = "#87BEBC";
                ctx.fillStyle = "rgba(135,190,188, 0.2)";
                ctx.stroke();
                ctx.fill();
            }

            // 그래프 배경 그리는 함수
            function drawBackground(ctx, r) {
                drawHexagon(ctx, r);
                drawHexagon(ctx, r * (6 / 7));
                drawHexagon(ctx, r * (5 / 7));
                drawHexagon(ctx, r * (4 / 7));
                drawHexagon(ctx, r * (3 / 7));
            }

            // 육각형 그리기
            function drawHexagon(ctx, r) {
                ctx.beginPath();
                for (let i = 0; i < 6; i++) {
                    ctx.lineTo(
                        350 + r * Math.cos((Math.PI / 3) * i - Math.PI / 2),
                        350 + r * Math.sin((Math.PI / 3) * i - Math.PI / 2),
                    );
                }
                ctx.closePath();
                ctx.strokeStyle = "#dedede";
                ctx.stroke();
            }
            // 각 지표 동그라미 그리기
            function drawCircles(ctx, r, size, px) {
                for (let i = 0; i < 6; i++) {
                    ctx.beginPath();
                    ctx.arc(
                        350 + r * Math.cos((Math.PI / 3) * i - Math.PI / 2),
                        350 + r * Math.sin((Math.PI / 3) * i - Math.PI / 2),
                        size,
                        0,
                        Math.PI * 2,
                    );
                    ctx.closePath();
                    ctx.fillStyle = "#b0b0b0";
                    ctx.fill();

                    ctx.beginPath();
                    ctx.fillStyle = "#fff";
                    ctx.font = `${px}px san-serif`;
                    ctx.fillText(
                        `${i + 1}`,
                        350 + r * Math.cos((Math.PI / 3) * i - Math.PI / 2) - size / 2 + 3,
                        350 + r * Math.sin((Math.PI / 3) * i - Math.PI / 2) + size / 2 - 1.5,
                    );
                    ctx.fill();
                    ctx.closePath();
                }
            }
            // 각 태그 그리기
            function drawTags(ctx, r, px, _this) {
                ctx.fillStyle = "#222";
                ctx.font = `${px}px san-serif`;

                ctx.beginPath();
                if (_this.popup.whichPopup == "상품") {
                    // 상품추천 - 판매자일때
                    ctx.fillText(
                        "카테고리",
                        350 + r * Math.cos((Math.PI / 3) * 0 - Math.PI / 2) - 48,
                        350 + r * Math.sin((Math.PI / 3) * 0 - Math.PI / 2) + 16,
                    );
                    ctx.fillText(
                        "공급방법",
                        350 + r * Math.cos((Math.PI / 3) * 1 - Math.PI / 2) - 58,
                        350 + r * Math.sin((Math.PI / 3) * 1 - Math.PI / 2) - 12,
                    );
                    ctx.fillText(
                        "제품특징",
                        350 + r * Math.cos((Math.PI / 3) * 2 - Math.PI / 2) - 60,
                        350 + r * Math.sin((Math.PI / 3) * 2 - Math.PI / 2) + 30,
                    );
                    ctx.fillText(
                        "제품공급시기",
                        350 + r * Math.cos((Math.PI / 3) * 3 - Math.PI / 2) - 28,
                        350 + r * Math.sin((Math.PI / 3) * 3 - Math.PI / 2) + 2,
                    );
                    ctx.fillText(
                        "예상마진",
                        350 + r * Math.cos((Math.PI / 3) * 4 - Math.PI / 2) + 8,
                        350 + r * Math.sin((Math.PI / 3) * 4 - Math.PI / 2) + 30,
                    );
                    ctx.fillText(
                        "단가",
                        350 + r * Math.cos((Math.PI / 3) * 5 - Math.PI / 2) + 10,
                        350 + r * Math.sin((Math.PI / 3) * 5 - Math.PI / 2) - 12,
                    );
                } else if (_this.popup.whichPopup == "공급자") {
                    // 공급자추천 - 판매자일때
                    ctx.fillText(
                        "카테고리",
                        350 + r * Math.cos((Math.PI / 3) * 0 - Math.PI / 2) - 48,
                        350 + r * Math.sin((Math.PI / 3) * 0 - Math.PI / 2) + 16,
                    );
                    ctx.fillText(
                        "공급경력",
                        350 + r * Math.cos((Math.PI / 3) * 1 - Math.PI / 2) - 70,
                        350 + r * Math.sin((Math.PI / 3) * 1 - Math.PI / 2) - 12,
                    );
                    ctx.fillText(
                        "공급가",
                        350 + r * Math.cos((Math.PI / 3) * 2 - Math.PI / 2) - 70,
                        350 + r * Math.sin((Math.PI / 3) * 2 - Math.PI / 2) + 30,
                    );
                    ctx.fillText(
                        "예상제조기간",
                        350 + r * Math.cos((Math.PI / 3) * 3 - Math.PI / 2) - 73,
                        350 + r * Math.sin((Math.PI / 3) * 3 - Math.PI / 2) + 2,
                    );
                    ctx.fillText(
                        "예상마진",
                        350 + r * Math.cos((Math.PI / 3) * 4 - Math.PI / 2) - 25,
                        350 + r * Math.sin((Math.PI / 3) * 4 - Math.PI / 2) + 30,
                    );
                    ctx.fillText(
                        "단가",
                        350 + r * Math.cos((Math.PI / 3) * 5 - Math.PI / 2) + 10,
                        350 + r * Math.sin((Math.PI / 3) * 5 - Math.PI / 2) - 12,
                    );
                } else if (_this.popup.whichPopup == "판매자") {
                    // 판매자추천 - 공급자일때
                    ctx.fillText(
                        "카테고리",
                        350 + r * Math.cos((Math.PI / 3) * 0 - Math.PI / 2) - 48,
                        350 + r * Math.sin((Math.PI / 3) * 0 - Math.PI / 2) + 16,
                    );
                    ctx.fillText(
                        "판매경력",
                        350 + r * Math.cos((Math.PI / 3) * 1 - Math.PI / 2) - 70,
                        350 + r * Math.sin((Math.PI / 3) * 1 - Math.PI / 2) - 12,
                    );
                    ctx.fillText(
                        "준비예산",
                        350 + r * Math.cos((Math.PI / 3) * 2 - Math.PI / 2) - 60,
                        350 + r * Math.sin((Math.PI / 3) * 2 - Math.PI / 2) + 30,
                    );
                    ctx.fillText(
                        "판매가능시점",
                        350 + r * Math.cos((Math.PI / 3) * 3 - Math.PI / 2) - 75,
                        350 + r * Math.sin((Math.PI / 3) * 3 - Math.PI / 2) + 2,
                    );
                    ctx.fillText(
                        "예상마진",
                        350 + r * Math.cos((Math.PI / 3) * 4 - Math.PI / 2) - 25,
                        350 + r * Math.sin((Math.PI / 3) * 4 - Math.PI / 2) + 30,
                    );
                    ctx.fillText(
                        "희망단가",
                        350 + r * Math.cos((Math.PI / 3) * 5 - Math.PI / 2) - 25,
                        350 + r * Math.sin((Math.PI / 3) * 5 - Math.PI / 2) - 12,
                    );
                }
                ctx.fill();
                ctx.closePath();
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/components/commonPopup_v2.css"></style>
