<template>
    <div class="content_container ai_matching">
            <div class="top_section">
                <div class="inner">
                        <div class="top_title">
                            <div class="top_btn_wrap">
                                <div class="top_btn on" @click="toggleTopBtn($event, 'auto')">
                                    <img
                                        src="@/assets/images/common/icon_ai.svg"
                                        alt="AI 자동 매칭"
                                    />
                                    <h2>AI 자동 매칭</h2>
                                    <p>AI가 자동으로 최적화된 매칭을 제안해줍니다.</p>
                                </div>
                                <div class="top_btn" @click="toggleTopBtn($event, 'self')">
                                    <img
                                        src="@/assets/images/common/icon_ai_matching.svg"
                                        alt="AI 타겟 매칭"
                                    />
                                    <h2>AI 타겟 매칭</h2>
                                    <p>직접 매칭을 검색합니다.</p>
                                </div>
                            </div>
                            <div class="top_menu" v-if="this.user.user_division == '판매자'">
                                <div class="toggle" id="menuListToggle"></div>
                                    <div class="menu_list">
                                    <p
                                        class="menuList menuList_seller on"
                                        @click="toggleMenuList(1, $event, '상품')"
                                    >
                                        상품 추천
                                    </p>
                                    <p
                                        class="menuList menuList_seller"
                                        @click="toggleMenuList(2, $event, '공급자')"
                                    >
                                        공급자 추천
                                    </p>
                                </div>
                            </div>
                            <p v-if="this.isAuto">
                                나에게 가장 잘 맞는
                                <span v-if="this.user.user_division == '판매자'"
                                ><span v-if="this.whichMenu == '상품'">상품을</span
                                ><span v-else>공급자를</span></span
                                >
                                <span v-if="this.user.user_division == '공급자'">판매자를</span>
                                추천합니다.
                            </p>
                            <p v-else>내가 선택한 타겟으로 <span>매칭</span>을 검색합니다.</p>
                        </div>

                    <template v-if="!this.isAuto">
                    <div class="box_category">
                        <div class="category on" @click="categoryCheck($event, null)">
                        <p>전체</p>
                        <img src="@/assets/images/main/icon_category_00.svg" alt="전체" />
                        <img
                            src="@/assets/images/main/icon_category_00_w.svg"
                            alt="전체"
                        />
                        </div>
                        <div class="category" @click="categoryCheck($event, this.basicData.category[1].category_pk)">
                        <p>{{this.basicData.category[1].content}}제품</p>
                        <img
                            src="@/assets/images/main/icon_category_01.svg"
                            alt="뷰티제품"
                        />
                        <img
                            src="@/assets/images/main/icon_category_01_w.svg"
                            alt="뷰티제품"
                        />
                        </div>
                        <div class="category" @click="categoryCheck($event, this.basicData.category[0].category_pk)">
                        <p>{{this.basicData.category[0].content}}제품</p>
                        <img
                            src="@/assets/images/main/icon_category_02.svg"
                            alt="패션제품"
                        />
                        <img
                            src="@/assets/images/main/icon_category_02_w.svg"
                            alt="패션제품"
                        />
                        </div>
                        <div class="category" @click="categoryCheck($event, this.basicData.category[2].category_pk)">
                        <p>{{this.basicData.category[2].content}}</p>
                        <img
                            src="@/assets/images/main/icon_category_03.svg"
                            alt="생활가구"
                        />
                        <img
                            src="@/assets/images/main/icon_category_03_w.svg"
                            alt="생활가구"
                        />
                        </div>
                        <div class="category" @click="categoryCheck($event, this.basicData.category[3].category_pk)">
                        <p>{{this.basicData.category[3].content}}</p>
                        <img
                            src="@/assets/images/main/icon_category_04.svg"
                            alt="생활소품"
                        />
                        <img
                            src="@/assets/images/main/icon_category_04_w.svg"
                            alt="생활소품"
                        />
                        </div>
                        <div class="category" @click="categoryCheck($event, this.basicData.category[4].category_pk)">
                        <p>{{this.basicData.category[4].content}}</p>
                        <img
                            src="@/assets/images/main/icon_category_05.svg"
                            alt="생활가전"
                        />
                        <img
                            src="@/assets/images/main/icon_category_05_w.svg"
                            alt="생활가전"
                        />
                        </div>
                        <div class="category" @click="categoryCheck($event, this.basicData.category[5].category_pk)">
                        <p>{{this.basicData.category[5].content}}</p>
                        <img
                            src="@/assets/images/main/icon_category_06.svg"
                            alt="식료품"
                        />
                        <img
                            src="@/assets/images/main/icon_category_06_w.svg"
                            alt="식료품"
                        />
                        </div>
                    </div>
                    <div class="box_detail">
                        <div class="tab_bar" @click="openDetail($event)">
                        <h1>상세 검색</h1>
                        <img src="@/assets/images/common/btn_select.svg" alt="btn_select" />
                        </div>
                        <div class="fill" :class="this.whichMenu == '상품' ? 'product_fill' : ''" id="fill">
                        <!-- 상품보기 : 카테고리, 예상마진, 최소수량, 제조기간, 판매방식, 판매지역, 판매채널 -->
                        <!-- 공급자보기 : 카테고리, 공급경력, 사업자유형 -->
                        <!-- 판매자보기 : 카테고리, 활동지역, 판매채널, 판매경력, 준비예산, 판매방법 -->

                        <!--예상마진 시작 - (상품보기) -->
                        <div v-if="this.whichMenu == '상품'" class="fill_check">
                            <h1 class="fill_box_title">예상마진</h1>
                            <div class="type">
                            <label v-for="(item, i) in this.basicData.margin" :key="i">
                                <input type="checkbox" name="margin" :value="item.margin_pk" />
                                {{item.content}}
                            </label>
                            </div>
                        </div>
                        <!--예상마진 끝-->

                        <!--최소수량 시작 - (상품보기) -->
                        <div v-if="this.whichMenu == '상품'" class="fill_check">
                            <h1 class="fill_box_title">최소수량</h1>
                            <div class="type">
                            <label v-for="(item, i) in this.basicData.moq" :key="i">
                                <input type="checkbox" name="moq" :value="item.moq_pk" />
                                {{item.content}}
                            </label>
                            </div>
                        </div>
                        <!--최소수량 끝-->

                        <!--제조기간 시작 - (상품보기) -->
                        <div v-if="this.whichMenu == '상품'" class="fill_check">
                            <h1 class="fill_box_title">제조기간</h1>
                            <div class="type">
                            <label v-for="(item, i) in this.basicData.start" :key="i">
                                <input type="checkbox" name="start" :value="item.start_pk" />
                                {{item.supply_content}}
                            </label>
                            </div>
                        </div>
                        <!--제조기간 끝-->

                        <!--판매채널 시작 - (상품보기, 판매자보기)-->
                        <div v-if="this.whichMenu == '상품' || this.whichMenu == '판매자'" class="fill_check">
                            <h1 class="fill_box_title">판매채널</h1>
                            <div class="type">
                            <label v-for="(item, i) in this.basicData.channel" :key="i">
                                <input type="checkbox" name="channel" :value="item.channel_pk" />
                                {{item.content}}
                            </label>
                            </div>
                        </div>
                        <!--판매채널 끝-->

                        <!--경력 시작 - (공급자보기, 판매자보기) -->
                        <div v-if="this.whichMenu == '공급자' || this.whichMenu == '판매자'" class="fill_check">
                            <h1 v-if="this.whichMenu == '공급자'" class="fill_box_title">공급경력</h1>
                            <h1 v-else class="fill_box_title">판매경력</h1>
                            <div class="type">
                            <label v-for="(item, i) in this.basicData.career" :key="i">
                                <input type="checkbox" name="career" :value="item.career_pk" />
                                {{this.whichMenu == '공급자' ? item.supply_user_content : item.sale_user_content}}
                            </label>
                            </div>
                        </div>
                        <!--경력 끝-->

                        <!--지역 시작 - (상품보기, 판매자보기) -->
                        <div v-if="this.whichMenu == '상품' || this.whichMenu == '판매자'" class="fill_check">
                            <h1 v-if="this.whichMenu == '상품'" class="fill_box_title">판매지역</h1>
                            <h1 v-else class="fill_box_title">활동지역</h1>
                            <div class="type national">
                            <label class="check_all">
                                <input
                                id="allNational"
                                type="checkbox"
                                @click="checkAll($event, '국내')"
                                />
                                국내
                            </label>
                            <label
                                v-for="(item, i) in this.basicData.area.filter(
                                (el) => el.division == '국내'
                                )"
                                :key="i"
                                class="area"
                            >
                                <input
                                type="checkbox"
                                name="national"
                                :value="item.area_pk"
                                @click="areaCheck($event, '국내')"
                                />
                                {{ item.content }}
                            </label>
                            </div>

                            <div class="type international">
                            <label class="check_all">
                                <input
                                type="checkbox"
                                id="allInternational"
                                @click="checkAll($event, '전세계')"
                                />
                                전세계
                            </label>
                            <label
                                v-for="(item, i) in this.basicData.area.filter(
                                (el) => el.division == '전세계'
                                )"
                                :key="i"
                                class="area"
                            >
                                <input
                                type="checkbox"
                                name="international"
                                :value="item.area_pk"
                                @click="areaCheck($event, '전세계')"
                                />
                                {{ item.content }}
                            </label>
                            </div>
                            
                        </div>
                        <!--지역 끝-->

                        <!--판매방식, 준비예산 시작 - (상품보기, 판매자보기)-->
                        <div v-if="this.whichMenu == '상품' || this.whichMenu == '판매자'" class="fill_check fill_double">
                            <!-- 판매방식 시작 - (상품보기, 판매자보기) -->
                            <div v-if="this.whichMenu == '상품' || this.whichMenu == '판매자'">
                            <h1 v-if="this.whichMenu == '상품'" class="fill_box_title">판매방식</h1>
                            <h1 v-else class="fill_box_title">판매방법</h1>
                            <div class="type">
                                <label v-for="(item, i) in this.basicData.way" :key="i">
                                <input type="checkbox" name="way" :value="item.way_pk" />
                                {{this.whichMenu == '상품' ? item.product_content : item.user_content}}
                                </label>
                            </div>
                            </div>
                            <!-- 판매방식 끝 -->

                            <!--준비예산 시작 - (판매자보기) -->
                            <div v-if="this.whichMenu == '판매자'">
                            <h1 class="fill_box_title">준비예산</h1>
                            <div class="type">
                                <label v-for="(item, i) in this.basicData.budget" :key="i">
                                <input type="checkbox" name="budget" :value="item.budget_pk" />
                                {{item.content}}
                                </label>
                            </div>
                            </div>
                            <!--준비예산 끝-->
                        </div>
                        <!--판매방식, 준비예산 끝-->

                        <!--사업자유형 시작 - (공급자보기) -->
                        <div v-if="this.whichMenu == '공급자'" class="fill_check">
                            <h1 class="fill_box_title">사업자유형</h1>
                            <div class="type">
                            <label v-for="(item, i) in this.basicData.businessman_division" :key="i">
                                <input type="checkbox" name="businessman_division" :value="item.businessman_division_pk" />
                                {{item.content}}
                            </label>
                            </div>
                        </div>
                        <!--사업자유형 끝-->

                        </div>
                    </div>
                    <button class="common_button" @click="detailSearch">검색</button>
                    </template>
                </div>
            </div>

        <div class="bottom_section">
                <!-- 공급자 계정일 때 (판매자 목록) -->
                <div class="common_table p_table" v-if="this.user.user_division == '공급자'">
                    <ul class="top">
                        <li class="w70">매칭순위</li>
                        <li class="w140">판매자</li>
                        <!-- <th>소개</th> -->
                        <li class="w240">카테고리</li>
                        <li class="product">공급방법</li>
                        <li class="w100">최근활동</li>
                        <!-- <th class="point">매칭포인트</th> -->
                        <li class="w60">보기</li>
                    </ul>
                    <ul
                        class="content"
                        v-for="(item, i) in this.showList"
                        :key="i"
                    >
                        <li class="w70 rank">{{i+1}}위</li>
                        <li class="w140 sub name"><p>{{ item.name }}</p></li>
                        <!-- <td class="introduce">{{item.introduce}}</td> -->
                        <li class="w240 sub">
                            <p>
                                <span v-for="(category, idx) in item.category" :key="idx">{{
                                    category.category
                                }}</span>
                            </p>
                        </li>
                        <li class="product">
                            <p>
                                <span v-for="(way, i) in item.way" :key="i">{{ way.way }}</span>
                            </p>
                        </li>
                        <li class="w100 sub" v-if="item.login_date">
                            <p>{{ this.dateFormat(item.login_date) }}</p>
                        </li>
                        <li v-else class="w100 sub"><p>-</p></li>
                        <li class="w60 icon" @click="openPopup(item.detail)">
                            <img src="@/assets/images/common/search.svg" alt="돋보기" />
                        </li>
                    </ul>
                    <ul class="default" v-if="this.showList.length == 0">
                        <li>추천 판매자가 없습니다.</li>
                    </ul>

                </div>

                <!-- 판매자 계정으로 상품 선택시 (상품 목록) -->
                <div class="common_table s_table_01"
                v-else-if="
                    this.user.user_division == '판매자' && this.whichMenu == '상품'
                "
                >
                    <ul class="top">
                        <li class="w70 rank">매칭순위</li>
                        <li class="w150">공급자</li>
                        <li class="product">상품명</li>
                        <li class="w100">카테고리</li>
                        <!-- <th>공급방법</th> -->
                        <li class="w120">마감일</li>
                        <!-- <th class="point">매칭포인트</th> -->
                        <li class="w60">보기</li>
                    </ul>
                    <ul
                        v-for="(item, i) in this.showList"
                        :key="i"
                        class="content"
                    >
                        <li class="w70 rank">{{i+1}}위</li>
                        <li class="w150 sub name"><p>{{ item.nick }}</p></li>
                        <li class="product"><p>{{ item.name }}</p></li>
                        <li class="w100 sub" v-if="typeof item.category == 'string'"><p>{{ item.category }}</p></li>
                        <li class="w100 sub" v-else><p>-</p></li>
                        <!-- <td>공급방법<br/>노출영역</td> -->
                        <li class="w120" v-if="item.finish_date">
                            <p>{{ this.dateFormat(item.finish_date) }}</p>
                        </li>
                        <li class="w120 sub" v-else>-</li>
                        <li class="w60 icon" @click="openPopup(item.detail)">
                            <img src="@/assets/images/common/search.svg" alt="돋보기" />
                        </li>
                    </ul>
                    <ul class="default" v-if="this.showList.length == 0">
                        <li>추천 상품이 없습니다.</li>
                    </ul>
                </div>
                
                <!-- 판매자 계정으로 공급자 선택시 (공급자 목록) -->
                <div class="common_table s_table_02"
                v-else-if="
                    this.user.user_division == '판매자' && this.whichMenu == '공급자'
                "
                >
                    <ul class="top">
                        <li class="w70">매칭순위</li>
                        <li class="product">공급자</li>
                        <!-- <th>상품명</th> -->
                        <li class="w240">카테고리</li>
                        <li class="w120">경력</li>
                        <li class="w130">최근활동</li>
                        <!-- <th class="point">매칭포인트</th> -->
                        <li class="w60">보기</li>
                    </ul>
                    <ul 
                        class="content"
                        v-for="(item, i) in this.showList"
                        :key="i"
                    >
                        <li class="w70 rank">{{i+1}}위</li>
                        <li class="product name"><p>{{ item.name }}</p></li>
                        <!-- <td>{{item.name}}</td> -->
                        <li class="w240 sub">
                            <p>
                                <span v-for="(category, idx) in item.category" :key="idx">{{
                                    category.category
                                }}</span>
                            </p>
                        </li>
                        <li class="w120 sub">{{ item.career }}</li>
                        <li 
                            class="w130 sub"
                            v-if="item.login_date">
                            {{ this.dateFormat(item.login_date) }}
                        </li>
                        <li class="w130 sub" v-else>-</li>
                        <li class="w60 icon" @click="openPopup(item.detail)">
                            <img src="@/assets/images/common/search.svg" alt="돋보기" />
                        </li>
                    </ul>
                    <ul class="default" v-if="this.showList.length == 0">
                        <li>추천 공급자가 없습니다.</li>
                    </ul>
                </div>
        </div>

        <AIPopup v-if="this.popup.showPopup" />
    </div>
</template>

<script>
import AIPopup from "@/components/AI/AIPopup";
import { mapState } from "vuex";
import { fetchData } from "@/utils/fetchData";
import { dateFormat } from "@/utils/dateFormat";

export default {
  components: {
    AIPopup,
  },
  mixins: [fetchData, dateFormat],
  computed: {
    ...mapState("ai", ["popup"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      isAuto: true,
      whichMenu: "",

      // 타겟 매칭에서 검색 전 리스트 가리기

      // 받아올 리스트
      productList: [],  // 상품 자동추천 리스트
      providerList : [],  // 공급자 자동추천 리스트
      sellerList : [],  // 판매자 자동추천 리스트
      showList: [],

      // 받아올 상세검색 메뉴들
      basicData : {},

      // 보낼 상세검색 데이터
      category: 0,
    };
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('ai/closePopup');
    next();
  },
  created() {
    this.fetchAIBasicData();
    if (this.user.user_division == "공급자") {
      this.fetchAutoAIData("판매자");
      this.whichMenu = "판매자";
    } else if (this.user.user_division == "판매자") {
      this.fetchAutoAIData("상품");
      this.whichMenu = "상품";
    }
  },
  watch : {
    isAuto(newVal){
      if(newVal){
        this.fetchAutoAIData(this.whichMenu);
      } else {
        this.showList = [];
      }
    }
  },
  methods: {
    // AI 기본 데이터 불러오기
    fetchAIBasicData(){
      this.fetchData('/getBasic?page=ai', (data)=>{
        this.basicData = data;
      })
    },
    // AI 데이터 불러오기
    fetchAutoAIData(which) {
      if (which == "상품") {
        if(this.productList.length==0){
          this.fetchData(
            "/getAiProduct",
            (data) => {
              this.productList = data.list.sort((a,b)=> b.ave - a.ave ).slice(0,10) ;
              this.showList = this.productList;
            },
          );
        } else {
          this.showList = this.productList;
        }
      } else if (which == "판매자") {
        if(this.providerList.length==0){
          this.fetchData(
            "/getAiSeller",
            (data) => {
              this.providerList = data.list.sort((a,b)=> b.ave - a.ave ).slice(0,10);
              this.showList = this.providerList;
            },
          );
        } else {
          this.showList = this.providerList;
        }
      } else {
        if(this.sellerList.length==0){
          this.fetchData(
            "/getAiProvider",
            (data) => {
              this.sellerList = data.list.sort((a,b)=> b.ave - a.ave ).slice(0,10);
              this.showList = this.sellerList;
            },
          );
        } else {
          this.showList = this.sellerList;
        }
      }
    },
    // 맨위 (자동매칭,수동매칭) top_btn 토글
    toggleTopBtn(event, which) {
      const topBtn = document.querySelectorAll(".top_btn");
      topBtn.forEach((el) => el.classList.remove("on"));
      event.currentTarget.classList.add("on");

      if (which == "auto") {
        this.isAuto = true;
      } else {
        this.isAuto = false;
      }
    },
    // 맨위 (상품 추천, 공급자추천) menuList 토글
    toggleMenuList(which, event, whichMenu) {
      if (this.whichMenu == whichMenu) {
        return;
      }
      this.whichMenu = whichMenu;
      const toggle = document.getElementById("menuListToggle");
      const menuList = document.querySelectorAll(".menuList");
      menuList.forEach((el) => el.classList.remove("on"));
      event.currentTarget.classList.add("on");
      if (which == 1) {
        toggle.style.left = 0;
      } else {
        toggle.style.left = "120px";
      }
      // 타겟매칭일경우
      if (!this.isAuto) {
        return;
      }
      // 자동매칭일 경우
      if (whichMenu == "상품") {
        this.fetchAutoAIData("상품");
      } else if (whichMenu == "공급자") {
        this.fetchAutoAIData("공급자");
      }
    },
    // 카테고리 선택
    categoryCheck(event, pk) {
      const categories = document.querySelectorAll(
        ".ai_matching .box_category .category"
      );
      categories.forEach((el) => el.classList.remove("on"));
      event.currentTarget.classList.add("on");

      this.category = pk;
    },
    // 상세검색 펼치기/접기
    openDetail(event) {
      event.currentTarget.classList.toggle("on");
      document.getElementById("fill").classList.toggle("on");
    },
    // 활동 지역 전체선택(국내/전세계)
    checkAll(event, division) {
      if (division == "국내") {
        const national = document.querySelectorAll('[name="national"]');
        if (event.currentTarget.checked) {
          national.forEach((el) => {
            el.checked = true;
          });
        } else {
          national.forEach((el) => {
            el.checked = false;
          });
        }
      } else {
        const international = document.querySelectorAll(
          '[name="international"]'
        );
        if (event.currentTarget.checked) {
          international.forEach((el) => {
            el.checked = true;
          });
        } else {
          international.forEach((el) => {
            el.checked = false;
          });
        }
      }
    },
    // 판매 지역 선택
    areaCheck(event, division) {
      if (!event.currentTarget.checked) {
        if (division == "국내") {
          document.getElementById("allNational").checked = false;
        } else {
          document.getElementById("allInternational").checked = false;
        }
      }
    },
    // 팝업 열기
    openPopup(detail) {
      this.$store.commit("ai/openPopup", {
        data: detail,
        whichPopup: this.whichMenu,
      });
    },
    // 상세 검색 적용
    detailSearch(){
      const margin = checked(document.querySelectorAll('input[name="margin"]'));
      const moq = checked(document.querySelectorAll('input[name="moq"]'));
      const start = checked(document.querySelectorAll('input[name="start"]'));
      const way = checked(document.querySelectorAll('input[name="way"]'));
      const area = checked(document.querySelectorAll('input[name="international"]')).concat(checked(document.querySelectorAll('input[name="national"]')));
      const channel = checked(document.querySelectorAll('input[name="channel"]'));
      const career = checked(document.querySelectorAll('input[name="career"]'));
      const businessman_division = checked(document.querySelectorAll('input[name="businessman_division"]'));
      const budget = checked(document.querySelectorAll('input[name="budget"]'));

      if (this.whichMenu == "상품") {
        this.fetchData(
        '/getAiTargetProduct', (data) => {
          this.showList = data.list.sort((a,b)=> b.ave - a.ave ).slice(0,10);
        }, {
          category : this.category,
          margin : margin,
          moq : moq,
          start : start,
          way : way,
          area : area,
          channel : channel,
        });
      } else if (this.whichMenu == "판매자") {
        this.fetchData(
        '/getAiTargetSeller', (data) => {
          this.showList = data.list.sort((a,b)=> b.ave - a.ave ).slice(0,10);
        }, {
          category : this.category,
          area : area,
          channel : channel,
          way : way,
          budget : budget,
          career : career,
        });
      } else {
        this.fetchData(
        '/getAiTargetProvider', (data) => {
          this.showList = data.list.sort((a,b)=> b.ave - a.ave ).slice(0,10);
        }, {
          category : this.category,
          career : career,
          businessman_division : businessman_division
        });
      }
      
      // nodelist 중 체크된것만 array에 'toString()'으로 return
      function checked(arr){
        const checked = [];
        arr.forEach(el=> {
          if(el.checked){
            checked.push(el.value);
          }
        })
        return checked.toString();
      }
      // this.$store.commit("alert", "검색 개발중");
    }
  },
};
</script>

<style scoped src="@/assets/css/layout_ai.css"></style>
<style scoped src="@/assets/css/components/commonPopup.css"></style>
